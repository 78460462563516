import { useGooglePublisherTags } from '@/providers/google-publisher-tags-provider';
import { useIsMinimumTablet } from '@spikemark/shared-hooks';
import { useEffect, useState } from 'react';

const HEADER_HEIGHT_LG = 138;
const HEADER_HEIGHT_SM = 116;

export function useStickyAdSlot({
  enabled: _enabled,
  timeoutAfterScroll,
}: {
  enabled: boolean;
  timeoutAfterScroll: number;
}): { sticky: boolean } {
  const { stickyAdSlotsEnabled } = useGooglePublisherTags();
  const [stickyDidTimeout, setStickyDidTimeout] = useState(false);
  const enabled = stickyAdSlotsEnabled && _enabled;
  const isMinimumTablet = useIsMinimumTablet();
  const headerHeight = isMinimumTablet ? HEADER_HEIGHT_LG : HEADER_HEIGHT_SM;

  useEffect(() => {
    if (!enabled) {
      return;
    }

    let timeoutId: ReturnType<typeof setTimeout>;
    const handleScroll = () => {
      if (window.scrollY >= headerHeight) {
        if (!stickyDidTimeout) {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            setStickyDidTimeout(true);
          }, timeoutAfterScroll);
        }
      } else {
        clearTimeout(timeoutId);
        setStickyDidTimeout(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [enabled, stickyDidTimeout, headerHeight]);

  return { sticky: Boolean(enabled && !stickyDidTimeout) };
}
