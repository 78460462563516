import { useMediaQuery as _useMediaQuery } from 'react-responsive';
import { useWindowSize } from '@uidotdev/usehooks';
import { useMemo } from 'react';

const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

type Breakpoint = keyof typeof screens;

const useMediaQuery = (settings: any) => {
  // If next is pre-rendering a page, we want to make sure it renders the
  // desktop screen, otherwise back buttons, etc will be statically rendered
  // and will flash when the page loads
  if (typeof window === 'undefined') {
    return true;
  }

  return _useMediaQuery(settings);
};

export const useIsMinimumMobile = () => useMediaQuery({ minWidth: 480 });

export const useIsMinimumTablet = () => useMediaQuery({ minWidth: 768 });

export const useIsMinimumDesktop = () => useMediaQuery({ minWidth: 1024 });

export const useIsMinimumDesktopLarge = () => useMediaQuery({ minWidth: 1280 });

export const useIsMinimumDesktopXLarge = () => useMediaQuery({ minWidth: 1440 });

/*If the width of the screen >=1441px, it is considered as fullscreen.
Use this hook for fullscreen specific render*/
export const useIsFullScreen = () => useMediaQuery({ minWidth: 1441 });

export const useIsPortrait = () => useMediaQuery({ orientation: 'portrait' });

export const useBreakpoint = (breakpoint: Breakpoint) =>
  useMediaQuery({ minWidth: screens[breakpoint] });

export const useBreakpointOptions = <T, DefaultT = null>(
  options: Partial<Record<Breakpoint, T>>,
  defaultValue: DefaultT
): T | DefaultT => {
  const { width } = useWindowSize();

  return useMemo(() => {
    if (width === null) {
      return defaultValue;
    }

    let breakpoint: Breakpoint | undefined;

    for (const option in options) {
      const breakpointWidth = screens[option as Breakpoint];

      if (width < breakpointWidth) {
        break;
      }

      breakpoint = option as Breakpoint;
    }

    return breakpoint ? (options[breakpoint] ?? defaultValue) : defaultValue;
  }, [width, options, defaultValue]);
};
