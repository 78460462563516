'use client';

import { AdSize, ViewportSize } from '@/core/ad-config';
import { useGooglePublisherTags } from '@/providers/google-publisher-tags-provider';
import { AdTargetingMap } from '@/types/advertising';
import { useSize } from '@spikemark/shared-hooks';
import { cn } from '@spikemark/ui/utils/cn';
import { FC, useId, useMemo, useRef, useState } from 'react';
import { useAdSetup } from './use-ad-setup';
import { useRefreshAdSlot } from './use-refresh-ad-slot';
import { useStickyAdSlot } from './use-sticky-ad-slot';
export type AdSlotProps = {
  adUnitPath: string;
  adUnitId: string;
  sizeMappings: Array<{
    viewport: ViewportSize;
    sizes: AdSize[];
  }>;
  targeting?: AdTargetingMap;
  location?: string;
  className?: string;
  unitClassName?: string;
  sticky?: boolean;
  heavy?: boolean;
};
export const AdSlot: FC<AdSlotProps> = ({
  adUnitPath,
  adUnitId,
  sizeMappings,
  heavy,
  targeting,
  location,
  // TODO: Leave this here for now, but how should we handle location?
  className,
  unitClassName,
  sticky: _sticky
}) => {
  const {
    availability,
    resizeEnabled
  } = useGooglePublisherTags();
  const divId = useId();
  const adRef = useRef<HTMLDivElement>(null);
  const adSize = useSize(adRef);
  const [divMounted, setDivMounted] = useState(false);
  const [hideAnimationEnded, setHideAnimationEnded] = useState(false);
  const enabled = useMemo(() => {
    if (availability === 'leaderboards_only') {
      return /leaderboard/i.test(adUnitPath);
    }
    return availability !== 'off' && (!heavy || availability === 'heavy');
  }, [availability, adUnitPath, heavy]);
  const {
    slot
  } = useAdSetup({
    adUnitPath,
    adUnitId,
    divId,
    sizeMappings,
    targeting,
    enabled: Boolean(enabled && divMounted)
  });
  const {
    sticky
  } = useStickyAdSlot({
    enabled: Boolean(enabled && divMounted && _sticky),
    timeoutAfterScroll: 1500
  });
  useRefreshAdSlot({
    slot,
    enabled,
    resizeEnabled
  });
  return enabled ? <div className="print:hidden" style={adSize} data-sentry-component="AdSlot" data-sentry-source-file="ad-slot.tsx">
      <div ref={adRef} className={cn('text-center', className, {
      'ad-sticky': !hideAnimationEnded,
      'ad-sticky-hide': !sticky && !hideAnimationEnded
    })} onAnimationEnd={() => {
      setHideAnimationEnded(true);
    }}>
        <div id={divId} ref={() => setDivMounted(true)} className={cn('m-auto py-2 md:py-4 lg:py-6 inline-flex items-center box-content empty:hidden min-h-[50px] md:min-h-[90px]', unitClassName)} data-ad-unit-path={adUnitPath} data-size={JSON.stringify(sizeMappings)} data-availability={availability} data-targeting={JSON.stringify(targeting)}></div>
      </div>
    </div> : null;
};