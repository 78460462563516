'use client';

import { AdvertisingAvailability, AdvertisingGlobalTargeting } from '@/types/advertising';
import Script from 'next/script';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
type GoogleTagLib = typeof window.googletag;
type GooglePublisherTagsProviderProps = {
  availability: AdvertisingAvailability;
  stickyAdSlotsEnabled?: boolean;
  globalTargeting?: AdvertisingGlobalTargeting;
  refreshInterval?: number;
  resizeEnabled?: boolean;
};
type GooglePublisherTagsContextType = GooglePublisherTagsProviderProps & {
  googletag: GoogleTagLib | null;
  isReady: boolean;
};
const GPT_TAGS_SCRIPT_URL = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
const GooglePublisherTagsContext = createContext<GooglePublisherTagsContextType>({} as GooglePublisherTagsContextType);
export const GooglePublisherTagsProvider: FC<PropsWithChildren<GooglePublisherTagsProviderProps>> = ({
  children,
  availability,
  stickyAdSlotsEnabled,
  globalTargeting,
  refreshInterval,
  resizeEnabled
}) => {
  const [googletag, setGoogletag] = useState<GoogleTagLib | null>(null);
  const [isReady, setIsReady] = useState(false);
  const handleGPTLoaded = useCallback(() => {
    if (window.googletag) {
      const googletag = window.googletag;
      setGoogletag(googletag);

      // Prepare GPT to display ads.
      googletag.cmd.push(() => {
        // Enable SRA and services.
        googletag.pubads().collapseEmptyDivs();
        googletag.pubads().disableInitialLoad();
        googletag.pubads().enableLazyLoad({
          fetchMarginPercent: 200,
          renderMarginPercent: 100,
          mobileScaling: 2.0 // Double the scaling on mobile devices.
        });
        if (globalTargeting?.enabled && globalTargeting.targeting) {
          for (const key in globalTargeting.targeting) {
            googletag.pubads().setTargeting(key, globalTargeting.targeting[key]);
          }
        }
        googletag.enableServices();
        setIsReady(Boolean(googletag.pubadsReady));
      });
    }
  }, [globalTargeting]);
  return <GooglePublisherTagsContext.Provider value={useMemo(() => ({
    googletag,
    availability,
    stickyAdSlotsEnabled,
    isReady,
    refreshInterval,
    globalTargeting
  }), [googletag, availability, stickyAdSlotsEnabled, isReady, refreshInterval, globalTargeting])} data-sentry-element="unknown" data-sentry-component="GooglePublisherTagsProvider" data-sentry-source-file="google-publisher-tags-provider.tsx">
      {children}
      <Script async type="text/javascript" src={GPT_TAGS_SCRIPT_URL} onLoad={handleGPTLoaded} data-sentry-element="Script" data-sentry-source-file="google-publisher-tags-provider.tsx" />
    </GooglePublisherTagsContext.Provider>;
};
export const useGooglePublisherTags = () => useContext(GooglePublisherTagsContext);